//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useIssueTypesStore = defineStore('issueTypesStore', {
  state: () => ({
    issueTypes: [] as any[]
  }),
  actions: {
    async fetchIssueTypes() {
      if (this.issueTypes.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/issueTypes')
          this.issueTypes = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
