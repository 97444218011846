//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useIssueStatusesStore = defineStore('issueStatusesStore', {
  state: () => ({
    issueStatuses: [] as any[]
  }),
  actions: {
    async fetchIssueStatuses() {
      if (this.issueStatuses.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/issueStatuses')
          this.issueStatuses = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
